export default class CountryTranslator {
  constructor(languages) {
    this.countryMappings = {};
    this.initialization = Promise.all(
      languages.map((lang) => this.initializeLanguage(lang)),
    );
  }

  async initializeLanguage(lang) {
    this.countryMappings[lang] = await (
      await fetch(`/countries/lang/${lang}.json`)
    ).json();
  }

  async getNameFromCountryCode(countryCode, lang) {
    await this.initialization;
    const lookup = this.countryMappings[lang].countries[countryCode];
    return Array.isArray(lookup) ? lookup[0] : lookup;
  }
  async getAlternativeNameFromCountryCode(countryCode, lang) {
    await this.initialization;
    const lookup = this.countryMappings[lang].countries[countryCode];
    return Array.isArray(lookup) ? lookup.slice(1) : null;
  }
}
