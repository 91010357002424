import React from "react";
import AnimationRevealPage from "components/helpers/AnimationRevealPage.js";
import MoonLoader from "react-spinners/ClipLoader";
import Header from "components/headers/Header";

import tw from "twin.macro";
const LoadingContainer = tw.div`flex justify-center items-center h-screen`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <LoadingContainer>
        <MoonLoader
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </LoadingContainer>
    </AnimationRevealPage>
  );
};
