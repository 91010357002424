import { createTheme } from "@mui/material/styles";
import tw, { theme as tailwindTheme } from "twin.macro";
const theme = createTheme({
  palette: {
    primary: {
      main: tailwindTheme`colors.primary.300`,
    },
    secondary: {
      main: tailwindTheme`colors.secondary.300`,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          ...tw`bg-primary-300 text-lg font-bold`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          ...tw`bg-primary-300 text-lg font-bold`,
          color: tailwindTheme`colors.black`,
        },
        outlinedSecondary: {
          backgroundColor: tailwindTheme`colors.secondary`,
          color: tailwindTheme`colors.white`,
        },
        sizeLarge: {
          height: "3.5rem",
          ...tw`px-8 text-base`,
        },
      },
    },
  },
});
export default theme;
