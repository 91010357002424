import React, { useEffect, Suspense } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./components/styles/Theme";
import GlobalStyles from "components/styles/GlobalStyles";
import Loading from "pages/Loading.js";

import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import {
  AnalyticsContextProvider,
  AuthContextProvider,
  DBContextProvider,
  auth,
  useAuthState,
  useDBState,
} from "./firebase";

const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const Business = React.lazy(() => import("./pages/Business"));
const Organisations = React.lazy(() => import("./pages/Organisations"));
const Stories = React.lazy(() => import("./pages/Stories"));
const GDPR = React.lazy(() => import("./pages/GDPR"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("./pages/TermsOfService"));
const RoamingStore = React.lazy(() => import("./pages/RoamingStore"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Checkout = React.lazy(() => import("pages/Checkout"));
const CheckoutPlan = React.lazy(() => import("pages/CheckoutPlan"));
const PostCheckout = React.lazy(() => import("pages/PostCheckout.js"));
const MyESIMs = React.lazy(() => import("pages/MyESIMs"));
const ESIMDetails = React.lazy(() => import("pages/ESIMDetails"));
const CompatibleDevices = React.lazy(() => import("./pages/CompatibleDevices"));
const PlanBuy = React.lazy(() => import("pages/PlanBuy"));
const Error = React.lazy(() => import("pages/Error"));
const Signup = React.lazy(() => import("pages/Signup"));
const VerifyEmail = React.lazy(() => import("pages/VerifyEmail"));
const Settings = React.lazy(() => import("pages/Settings"));
const Reset = React.lazy(() => import("pages/Reset"));
const MainLandingPage = React.lazy(() => import("pages/MainLandingPage.js"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage.js"));
const BlogRender = React.lazy(() => import("BlogRender.js"));
const Pricing = React.lazy(() => import("pages/Pricing"));
const Login = React.lazy(() => import("./pages/Login"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthContextProvider>
          <DBContextProvider>
            <AnalyticsContextProvider>
              <Router>
                <ScrollToTop />
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <GeneralOutlet>
                          <MainLandingPage />
                        </GeneralOutlet>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <PublicOutlet>
                          <Login />
                        </PublicOutlet>
                      }
                    />
                    <Route
                      path="/signup"
                      element={
                        <PublicOutlet>
                          <Signup />
                        </PublicOutlet>
                      }
                    />
                    <Route
                      path="/reset"
                      element={
                        <PublicOutlet>
                          <Reset />
                        </PublicOutlet>
                      }
                    />
                    <Route
                      path="/verify-email"
                      element={
                        <LoggedInOutlet>
                          <VerifyEmail />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/account"
                      element={
                        <LoggedInOutlet>
                          <Settings />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <LoggedInOutlet>
                          <Settings />
                        </LoggedInOutlet>
                      }
                    />
                    <Route path="/home" element={<Navigate to="/" />} />
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="/blog/:name" element={<BlogRender />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/organisations" element={<Organisations />} />
                    <Route path="/stories" element={<Stories />} />
                    <Route path="/gdp" element={<GDPR />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route
                      path="/terms-of-service"
                      element={<TermsOfService />}
                    />
                    <Route
                      path="/store"
                      element={
                        <GeneralOutlet>
                          <RoamingStore />
                        </GeneralOutlet>
                      }
                    />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route
                      path="/store/country/:name"
                      element={
                        <GeneralOutlet>
                          <Pricing type="country" />
                        </GeneralOutlet>
                      }
                    />
                    <Route
                      path="/store/country/:name/:plan"
                      element={
                        <GeneralOutlet>
                          <PlanBuy type="country" />
                        </GeneralOutlet>
                      }
                    />
                    <Route
                      path="/store/region/:name"
                      element={
                        <GeneralOutlet>
                          <Pricing type="region" />
                        </GeneralOutlet>
                      }
                    />
                    <Route
                      path="/store/region/:name/:plan"
                      element={
                        <GeneralOutlet>
                          <PlanBuy type="region" />
                        </GeneralOutlet>
                      }
                    />
                    <Route
                      path="/store/checkout/:id/complete"
                      element={
                        <LoggedInOutlet>
                          <PostCheckout />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/store/checkout/:id"
                      element={
                        <LoggedInOutlet>
                          <Checkout />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/store/checkout/plan/:id"
                      element={
                        <LoggedInOutlet>
                          <CheckoutPlan />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/myesims"
                      element={
                        <LoggedInOutlet>
                          <MyESIMs />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/myesims/details/:iccid"
                      element={
                        <LoggedInOutlet>
                          <ESIMDetails />
                        </LoggedInOutlet>
                      }
                    />
                    <Route
                      path="/compatible-devices"
                      element={
                        <GeneralOutlet>
                          <CompatibleDevices />
                        </GeneralOutlet>
                      }
                    />
                  </Routes>
                </Suspense>
              </Router>
            </AnalyticsContextProvider>
          </DBContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </>
  );
}

const ProcessQueryParameters = () => {
  const [searchParams] = useSearchParams()

  for (const key of ["t"]) {
    if (searchParams.has(key)) {
      sessionStorage.setItem(key, searchParams.get(key));
    }
  }
}

const GeneralOutlet = (props) => {
  const { children } = props;

  ProcessQueryParameters();

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

const LoggedInOutlet = (props) => {
  const { children } = props;
  const { pending } = useDBState();
  const { isAuthenticated } = useAuthState();
  const { pathname } = useLocation();

  ProcessQueryParameters();

  if (pending) {
    return <Loading />;
  }

  if (isAuthenticated) {
    // check if allowed to view
    if (pathname !== "/verify-email" && !auth.currentUser.emailVerified) {
      return <Navigate to="/verify-email" />;
    }
    if (pathname === "/verify-email" && auth.currentUser.emailVerified) {
      return <Navigate to="/" />;
    }
    return (
      <>
        {children}
        <Outlet />
      </>
    );
  }

  return <Navigate to="/login" state={ { path: pathname } } />;
};

//Use for routes that a logged in user cannot access (login, register)
const PublicOutlet = ({ children }) => {
  const { isAuthenticated } = useAuthState();

  ProcessQueryParameters();

  let redirectPath = "/";
  if (isAuthenticated) {
    // Check whether we were on another page before logging in
    const redirectTo = sessionStorage.getItem("redirectTo");
    if (redirectTo !== null) {
      // Cleanup the state to prevent strange redirections
      sessionStorage.removeItem("redirectTo");

      // Ensure we don't start an indefinite loop
      if (!redirectTo.toLowerCase().startsWith("/login")) {
        redirectPath = redirectTo;
      }
    }

    return <Navigate to={redirectPath} replace={true} />;
  }

  return <>
    {children}
    <Outlet />
  </>;
};
