import React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDBState } from "../../firebase";

export default () => {
  const { currencies, currency, setCurrency } = useDBState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePick = (name) => {
    setCurrency(name);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Currency Picker">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "currency-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          key={`picker-${currency}`}
        >
          <Avatar
            key={`picker-avatar-${currency}`}
            sx={{ width: 32, height: 32 }}
          >
            {currencies.find((curr) => curr.name === currency).symbol}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="currency-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "bg-primary-300",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {currencies.map((curr) => (
          <MenuItem
            key={`currency-item-${curr.name}`}
            selected={curr.name === currency}
            onClick={() => handlePick(curr.name)}
          >
            <Avatar key={`currency-avatar-${curr.name}`}>{curr.symbol}</Avatar>{" "}
            {curr.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
